:root {
  --vw: 1vw;
  --vh: 1vh;
}

$bg-col: #ffffff;
$contrast-col-1: #ffffff;
$contrast-col-2: #0f0f0f;

$ac-col: #FF004D; /* Accent colour */
$ac-high: #ff7ea5; /* Accent highlighted */

$nav-col: #FF004D;

$header-col-1: #FFD900;
$header-col-2: #FF004D;

$option-col-1: #620CE8;
$option-col-2: #0D98FF;

$feature-col-1: #0D98FF;
$feature-col-2: #5cb8ff;

$txt-col: #ffffff;
$txt-gray: #505050;

$nav-height: 8vh;
$nav-padding: 1vh;
$nav-shad: 0px 5px 10px -5px rgba(0, 0, 0, 0.5); /* Navbar box shadow */
$box-shad: 0px 5px 15px -10px rgba(0, 0, 0, 0.5); /* General box shadow */

$hover-shift: 5px;
$hover-shad: 0px ($hover-shift * 2) 20px -10px rgba(0, 0, 0, 0.5); /* General hover box shadow */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

@mixin fancyText {
  background: linear-gradient(90deg, $option-col-1 0%, $option-col-2 100%);
  background-clip: initial;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes stretch {
  from {
    letter-spacing: 0;
  }
  to {
    letter-spacing: 1vw;
  }
}

@keyframes unblur {
  from {
    filter: blur(10px);
  }
  to {
    filter: blur(0px);
  }
}

html, body, .root {
  overflow-x: hidden;
}

body {
  background: $bg-col;
}

a {
  color: currentColor;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: $ac-col;
  }
}

::-webkit-scrollbar {
  display: none;
}

.page-container {
  display: flex;
  flex-direction: column;
}

.loader {
  position: absolute;

  width: 100%;
  height: 100%;
  
  z-index: -1;
  
  background: linear-gradient(180deg,
      $header-col-1 0%,
      $header-col-2 100%);
}

.profile-photo {
  width: 80%;
  height: 80%;
  min-width: 80%;
  min-height: 80%;
  max-width: 80%;
  max-height: 80%;

  border-radius: 50%;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 5vh;
  min-width: 5vh;
  min-height: 5vh;

  padding: 0;

  background: linear-gradient(90deg,
  $option-col-1 0%,
  $option-col-2 100%);
  color: $txt-col;

  border: none;
  border-radius: 2.5vh;
  box-shadow: $box-shad;

  font-size: 2vh;
  line-height: 2vh;

  cursor: pointer;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  border-radius: 50%;
}

.navbar {
  position: fixed;
  display: flex;
  flex-direction: row-reverse;

  width: 100%;

  color: $txt-col;

  z-index: 9999;

  &__nav-slider {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex-direction: row-reverse;

    margin: $nav-padding;
    background: $nav-col;
  }

  &__nav-section {
    display: flex;
    justify-content: space-between;

    height: $nav-height;
    margin: 0px;
    padding-left: 0px;
  }

  &__nav-item-container {
    flex-grow: 0;
  }

  &__nav-button-container {
    flex-grow: 1;
  }

  &__nav-button, &__nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
  
    width: $nav-height;
  }

  &__nav-button {
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: $nav-height * 0.75;
      height: $nav-height * 0.75;

      padding: 0;
    
      background: none;
      border: none;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  &__nav-icon {
    color: $txt-col;
    font-size: $nav-height * 0.4;
  }
}

.user-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: #00000033;

  z-index: 10000;

  &__content {
    display: flex;
    flex-direction: column;

    background: $contrast-col-1;
    border-radius: 10px;

    box-shadow: $hover-shad;

    z-index: 20000;

    &--logged-out {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 2vh;
    }

    &--logged-in {
      width: 80%;
      height: 80%;

      padding: 5vh;
    }

    &__heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__user {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        width: 100%;

        &__photo, &__icon, &__name {
          margin-top: 0;
          margin-bottom: 0;
        }

        &__photo, &__icon {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 10vh;
          height: 10vh;

          // border: 5px solid $ac-col;
          border-radius: 50%;
        }

        &__icon {
          background: linear-gradient(90deg, $option-col-1 0%, $option-col-2 100%);
          color: $contrast-col-1;

          font-size: 7vmin;
          
          overflow: hidden;
        }

        &__name {
          @include fancyText;

          margin-left: 2vh;

          font-size: 5vmin;
        }
      }

      &__exit {
        align-self: start;
      }
    }

    &__close {
      margin-top: 1vmin;
    }

    &__sign-out-container {
      display: flex;
      justify-content: center;
    }

    &__sign-out {
      width: 25%;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: linear-gradient(180deg,
      $header-col-1 0%,
      $header-col-2 100%);
  color: $txt-col;

  .sketch {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    background: transparent;

    animation: unblur 1s ease-out;
  }

  :not(.sketch) {
    z-index: 1;
  }

  &__title, &__subtitle {
    margin: 0.25rem;
    font-weight: 300;
  }

  &__title {
    font-size: 10vmin;
    letter-spacing: 1vw;

    animation: stretch 1s ease-out;
  }

  &__title-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__subtitle {
    font-size: 4vmin;
  }

  &__title-icon {
    margin-bottom: 1rem;
  }
}

.down-nav {
  position: absolute;
  
  border-radius: 50%;
  cursor: pointer;

  &__button {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    background: none;
    border: none;
    
    color: $txt-col;
  }
}

.content {
  align-self: center;
}

.features {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: stretch;

  width: 100%;
  height: 100vh;

  margin: 0;
  padding: 0;

  background: #505050;

  &__feature {
    flex-basis: 50%;

    margin: 0;
    padding: 0;

    list-style-type: none;

    &:nth-child(1), &:nth-child(4) {
      background-color: $feature-col-1;
      color: $bg-col;
    }
  
    &:nth-child(2), &:nth-child(3) {
      background-color: $bg-col;
      color: $feature-col-1;
    }
  }
}

.options {
  display: flex;
  flex-flow: row;
  justify-content: center;

  width: 100%;
  max-width: 100%;

  color: $contrast-col-1;

  &__option {
    width: 25%;

    background: $contrast-col-1;

    box-shadow: $box-shad;
    border-radius: 10px;

    text-align: center;

    &__title-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      height: 10vh;
      margin: 0;

      background: linear-gradient(90deg,
      $option-col-1 0%,
      $option-col-2 100%);
      color: $txt-col;

      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      // clip-path: url(#wave);

      .options__option__title {
        margin: 0px;
        padding: 0px;

        font-size: 2rem;
        font-weight: 400;
        letter-spacing: 0vw;
      }

      .options__option__subtitle {
        margin: 0px;
        padding: 0px;

        font-size: 1.25rem;
        font-weight: 400;
        letter-spacing: 0vw;
      }
    }

    &__features-container {
      margin-left: 3vh;
      margin-right: 3vh;
    }

    &__features {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      height: 100%;

      padding: 0;

      font-size: 1.25vw;
      list-style-type: none;

      &__feature {
        &--new {
          @include fancyText;
        }

        color: $txt-gray;
        text-align: center;
        // margin-bottom: 2.5vh;
      }
    }

    &__cta-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 100%;

      height: 10vh;
    }

    &__cta {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 50%;

      &:active {
        color: $txt-col;
      }

      .icon, &__text {
        display: inline;
      }
    }
  }
}

.footer {
  background: $contrast-col-2;
  color: $txt-col;

  &__copyright {
    width: 100%;
    background: $contrast-col-2;
    font-weight: bold;
    text-align: center;
  }
}

@media (hover: hover) {
  .button {
    &:hover {
      // letter-spacing: 3px;
      filter: hue-rotate(90deg);
    }
  }

  .navbar {
    // opacity: 0.75;

    &:hover {
      // opacity: 1;
      
      .navbar__nav-slider {
        width: 100%;
    
        border-radius: $nav-padding;
    
        -webkit-box-shadow: $nav-shad;
           -moz-box-shadow: $nav-shad;
                box-shadow: $nav-shad;
      }

      .navbar__nav-button-container {
        opacity: 1;
        visibility: visible;
      }
      
      .navbar__nav-button-container {
        opacity: 1;
        visibility: visible;
      }

      .navbar__nav-item-container {
        transform: rotate(-90deg);
      }
    }

    &__nav-slider {
      width: $nav-height;
  
      border-radius: $nav-height * 0.5;
      border-top-right-radius: $nav-padding;
    }

    &__nav-button-container {
      opacity: 0;
      visibility: hidden;
    }

    &__nav-button button {
      &:hover {
        background: $ac-high;
      }

      &:active {
        background: none;
      }
    }
  }

  .header {
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;

    &__title-inner {
      margin-top: -8vh;
    }

    &__title-icon {
      font-size: 10vh;
    }
  }

  .down-nav {
    bottom: 5vh;
    width: 15vh;
    height: 15vh;

    &:hover {
      background: $ac-high;
    }
  
    &:active {
      background: none;
    }
  }

  .options {
    &__option {
      margin-bottom: 5vh;
      margin-left: 2vw;
      margin-right: 2vw;

      &:hover {
        box-shadow: $hover-shad;
        transform: scale(1.01);

        .options__option {
          &__title-container, &__features-container {
            filter: hue-rotate(90deg);
          }
        }

        .options__option__title-container {
          letter-spacing: 0.5vw;
        }
      }

      &__cta:hover {
        color: $txt-col;
      }
    }
  }
}

@media (hover: none) {
  .navbar {
    &__nav-slider {
      width: 100%;

      border-radius: $nav-padding;

      -webkit-box-shadow: $nav-shad;
        -moz-box-shadow: $nav-shad;
              box-shadow: $nav-shad;
    }

    &__nav-item-container {
      display: none;
    }

    &__nav-button:active {
      background: $ac-high;
    }
  }

  .header {
    min-height: calc(var(--vh) * 100);
    max-height: calc(var(--vh) * 100);
    height: calc(var(--vh) * 100);

    &__title-inner {
      margin-top: calc(var(--vh) * -5);
    }

    &__title-icon {
      font-size: calc(var(--vh) * 10);
    }
  }

  .down-nav {
    bottom: calc(var(--vh) * 5);
    width: calc(var(--vh) * 15);
    height: calc(var(--vh) * 15);
  }

  .options {
    &__option {
      margin-bottom: calc(var(--vh) * 5);
      margin-left: calc(var(--vw) * 2);
      margin-right: calc(var(--vw) * 2);
    }
  }

  .features {
    flex-direction: row;

    height: calc(var(--vh) * 100);
  }
}

@media only screen and (min-width: 769px) {
  .content {
    width: 75%;
  }

  .options {
    &__option {
      &__features-container {
        height: 30vh;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .content {
    width: 100%;
  }

  .user-modal {
    &__content {
      &__sign-out {
        width: 80%;
      }
    }
  }

  .options {
    display: initial;

    &__option {
      display: block;
      width: initial;

      margin: 0 calc(var(--vw) * 5);
      margin-bottom: calc(var(--vh) * 5);

      &__features-container {
        height: calc(var(--vh) * 100 - #{$nav-height} - 40vh);
      }

      &__features, &__cta {
        font-size: 3vh;
      }

      &__features {
        padding: 0;
      }
    }
  }

  .features {
    flex-direction: column;
  }
}

@media (prefers-reduced-motion: no-preference) {
  body * {
    transition: 0.25s ease-in-out;
  }
}

@media (prefers-reduced-motion: reduce) {
  body * {
    animation: none !important;
  }
}